<template>
    <div id="box">
        <Header :headerIndex="4"></Header>
        <div class="container pb1">
            <div class="tran_desc">
                <div class="fs20 fwb pb30">TRANSACTION DESCRIPTION</div>
                <div class="liiustrate p40 mb30">
                    <div class="fs20 col10 fwb pb20">TRADING RULES</div>
                    <div class="fs16 col3">1. The platform sets the total transaction fund pool amount based on the number of registered users
                        and the total amount of tokens;
                        2. Calculate the rise and fall values based on the daily number of tokens listed by users;
                        3. Funds that have been self registered and recharged can purchase tokens with a validity period of
                        90 days. If no purchase is made after 90 days, it will automatically become invalid;</div>
                </div>
                <div class="liiustrate p40 mb30">
                    <div class="fs20 col10 fwb pb20">TRADING MARKET</div>
                    <div class="fs16 col3">1. The platform sets the total transaction fund pool amount based on the number of registered users
                        and the total amount of tokens;
                        2. Calculate the rise and fall values based on the daily number of tokens listed by users;
                        3. Funds that have been self registered and recharged can purchase tokens with a validity period of
                        90 days. If no purchase is made after 90 days, it will automatically become invalid;</div>
                </div>
                <div class="liiustrate p40">
                    <div class="fs20 col10 fwb pb20">BUY-BACK</div>
                    <div class="fs16 col3">1. The platform sets the total transaction fund pool amount based on the number of registered users
                        and the total amount of tokens;
                        2. Calculate the rise and fall values based on the daily number of tokens listed by users;
                        3. Funds that have been self registered and recharged can purchase tokens with a validity period of
                        90 days. If no purchase is made after 90 days, it will automatically become invalid;</div>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>
<script>
export default {
    data() {
        return {

        };
    },
    methods: {

    }
};
</script>
